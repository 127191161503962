<template>
  <!--
    The view for the AcknowledgeProblem-component.
    Used to acknowledge one problem.
  -->
  <AcknowledgeProblem
    :source="source"
    :trigger-id="triggerId"
    :installation-id="installationId"
  />
</template>

<script>
export default {
  name: "ViewAcknowledgeProblem",
  components: {
    AcknowledgeProblem: () => import('@/components/Problem/AcknowledgeProblem.vue')
  },
  props: {
    triggerId: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('acknowledgeProblem')
    }
  }
}
</script>
